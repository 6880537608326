@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang&display=swap');

@font-face {
  font-family: 'BatangChe.ttf';
  src: url('Fonts/BatangChe.ttf') format('truetype');
}

body {
  background-color: white;
  font-family: "Gowun Batang", serif;
  overflow: auto; 
  position: relative;
  min-height: 100vh;
  display:flex;
  color: black;
    flex-direction: column;
}

@media (max-width: 1366px) {
  body {
    overflow: auto; 
  }
}

.main {
  height: 75vh;
}





.logo {
  filter: invert(1);
}

nav {
  font-family: "Gowun Batang", serif;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(19, 19, 19);
}

.borderbottom {
  border-bottom: white 1px solid;
}
footer {
    align-self: end;
    grid-column: 2;
    background-color: white;
    bottom: 0;
    padding: 0;
    margin-top: auto;
}

.error {
  height: 100vh;
}.live {
  min-height:100vh;
position: relative;
}
.music-detail {
  min-height:100vh;
position: relative;
}

.livebutton:hover {
  border: white 4px solid;
}

.omerta-logo {
  filter: invert(1);
}

footer {
  filter: invert(1);
  border-top: 1px solid black;
}

.yama {
  filter: invert(1);
}

.frame {
  border: white solid 1px;
}

.header-container {
  height: 100vh;
}


